
export default {
  name: 'HeroCta',
  inject: ['getProps'],
  props: {
    pushBottom: Boolean,
    isInsideLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    p () {
      return this.getProps()
    },
    pushBottomAlignment () {
      if (!this.pushBottom) {
        return {}
      }

      switch (this.p.ctaAlign) {
        case 'left':
          return { left: '0' }
        case 'right':
          return { right: '0' }
        case 'center':
        default:
          return { left: '50%', transform: 'translateX(-50%)' }
      }
    }
  }
}
